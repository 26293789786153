
  import React from 'react';;

  export const frontMatter = {};

  export default class ReactMarkdown extends React.PureComponent {
    render() {
      const props = this.props;

      return <div>
  <h2>This is some content loaded from a markdown file</h2>
  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. <strong>Praesent imperdiet enim vitae</strong> erat mattis malesuada. Integer ullamcorper accumsan turpis. Vivamus quis dictum sem, eu rutrum sem. Etiam efficitur vehicula consequat. Quisque porttitor eu nibh in elementum. Quisque accumsan justo a justo pretium porttitor sit amet id augue. In hac habitasse platea dictumst. Mauris sem dolor, porttitor et fringilla id, iaculis gravida lectus. Nunc condimentum lorem a porttitor mattis.</p>
  <ol>
    <li>
      <p>Nulla sit amet malesuada urna. Duis sit amet massa nec magna dapibus pharetra a <a href="https://google.com" target="_blank" rel="noopener noreferrer">website</a>.</p>
    </li>
    <li>
      <p>Phasellus condimentum neque et ex tincidunt, ut aliquet lacus auctor.</p>
    </li>
    <li>
      <p>Aliquam nec vehicula elit. Fusce pretium tempus diam vel ornare. Proin id efficitur elit. Nulla vel suscipit lacus, vitae cursus erat.</p>
    </li>
  </ol>
  <p>Morbi enim neque, pharetra quis mollis a, molestie id sem. Morbi dignissim mi id ultrices finibus. Curabitur tempor nibh sit amet nulla tempor, nec ultrices ante faucibus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Duis eleifend, sapien eget suscipit lacinia, tellus risus blandit dolor, nec ultrices nisl ante at nisi. Proin dapibus mi ac tempor accumsan. Nunc nisl lectus, pharetra et euismod sed, pulvinar ac ante. Sed scelerisque sit amet quam ut fermentum. Praesent hendrerit orci gravida, congue est nec, congue magna. Donec vel fringilla risus. Aliquam nec vehicula velit. Suspendisse id tortor vel diam tempor facilisis eget eget lorem. Cras semper ante ut lorem efficitur dapibus. Fusce a dapibus nibh, id ullamcorper dolor. Donec dolor enim, faucibus vitae dolor ut, aliquet molestie justo.</p>
</div>
;
    }
  }