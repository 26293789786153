/* eslint-disable id-length */
export default {
	'React Skeleton': {
		en: 'React Skeleton',
		fr: 'React Skeleton (FR)'
	},
	'{siteTitle} - Home': {
		en: '{siteTitle} - Home',
		fr: '{siteTitle} - Home (FR)'
	},
	'{siteTitle} - Not Found': {
		en: '{siteTitle} - Not Found',
		fr: '{siteTitle} - Not Found (FR)'
	},
	'Home': {
		en: 'Home',
		fr: 'Home (FR)'
	},
	'Search': {
		en: 'Search',
		fr: 'Search (FR)'
	},
	'Submit search': {
		en: 'Submit search',
		fr: 'Submit search (FR)'
	},
	'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in.': {
		en: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in.',
		fr: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in. (FR)'
	},
	'More info': {
		en: 'More info',
		fr: 'More info (FR)'
	},
	'Close': {
		en: 'Close',
		fr: 'Close (FR)'
	},
	'Close notification': {
		en: 'Close notification',
		fr: 'Close notification (FR)'
	}
};
