import React from 'react';
import { Translate } from 'react-translated';
import { useLocation } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

import navItems from '../../config/navItems';

function Footer() {
	let location = useLocation();
	const currentPath = location.pathname;

	return (
		<Container as="footer" fluid={ true } className="footer">
			<Row>
				<Col md={6}>
					<p>
						<Translate text="Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in." />
					</p>
				</Col>
				<Col md={6}>
					<Navbar
						collapseOnSelect
						expand="lg"
					>
						<div className="navbar-wrapper">
							<Nav className="primary-nav justify-content-end">
								{ Array.isArray(navItems) && navItems.map((item, index) => {
									let isActive = false;
									let display = true;

									if (item.activePaths && item.activePaths.length) {
										if (item.activePaths.includes(currentPath)) {
											isActive = true;
										}

										item.activePaths.reduce((a, b) => { // eslint-disable-line
											if (RegExp(b, 'g').test(currentPath)) {
												isActive = true;
											}
										});
									}

									if (display) {
										return (
											<Nav.Item key={ index }>
												<Nav.Link
													href={ item.href }
													active={ isActive }
												>
													<Translate text={ item.text } />
												</Nav.Link>
											</Nav.Item>
										);
									}

									return null;
								})}
							</Nav>
						</div>
					</Navbar>
				</Col>
			</Row>
		</Container>
	);
}

export default Footer;
