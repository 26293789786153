/* eslint-disable id-length */
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import CheckBox from '../custom/CheckBox';
import RadioButton from '../custom/RadioButton';

// Icons.
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faInfoCircle,
	faCheckCircle,
	faExclamationCircle,
	faExclamationTriangle,
	faPencilAlt,
	faSearch,
	faArrowLeft,
	faArrowRight,
	faArrowUp,
	faArrowDown,
	faLock,
	faBell,
	faFilePdf,
	faFileWord,
	faCloudUploadAlt,
	faPrint,
	faShare,
	faShareAlt,
	faPaperclip
} from '@fortawesome/free-solid-svg-icons';
import {
	faClock,
	faCalendar,
	faTimesCircle,
	faFileAlt
} from '@fortawesome/free-regular-svg-icons';

function FormExample() {
	return (
		<form noValidate >
			<Col md="12">
				<Row>
					<Col md="6">
						<label htmlFor="formBasicText">Text<span className="required">*</span></label>
						<input type="text" id="formBasicText" className="form-control"></input>
					</Col>
				</Row>
				<Row>
					<Col md="6">
						<RadioButton labelText={'Test radio button 1'}></RadioButton>
						<RadioButton labelText={'Test radio button 2'}></RadioButton>
						<RadioButton labelText={'Test radio button 3'}></RadioButton>
					</Col>
					<Col md="6">
						<CheckBox labelText={'Test Checkbox 1'}></CheckBox>
						<CheckBox labelText={'Test Checkbox 2'}></CheckBox>
						<CheckBox labelText={'Test Checkbox 3'}></CheckBox>
					</Col>
				</Row>
			</Col>
		</form>
	);
}

function ReportHeadingCard() {
	return (
		<div className="report-heading-card-body">
			<h2>Heading Card</h2>
			<p className="bold">FAR-210: FAR 2023-06 Q2 RETAINER</p>
			<p>REPORT GENERATED: 2023-04-18</p>
		</div>
	);
}

function App() {
	return (
		<div className="page-wrapper">
			<Header />

			<Container as="main" fluid={true} className="main">
				<Row>
					<Col md="12">
						<h2>Colors</h2>
						<hr />
					</Col>
					<Col md="4">
						<Card bg="primary">
							<Card.Body>
								<Card.Title>
									Primary
								</Card.Title>
								<Card.Text>
									{ /* eslint-disable jsx-a11y/anchor-is-valid */}
									Lorem ipsum dolor sit amet, <a href="#">consectetur adipiscing elit</a>. Pellentesque tincidunt lectus velit, vel pulvinar nibh auctor nec.
									{ /* eslint-enable jsx-a11y/anchor-is-valid */}
								</Card.Text>
							</Card.Body>
						</Card>
					</Col>
					<Col md="4">
						<Card bg="secondary">
							<Card.Body>
								<Card.Title>
									Secondary
								</Card.Title>
								<Card.Text>
									{ /* eslint-disable jsx-a11y/anchor-is-valid */}
									Lorem ipsum dolor sit amet, <a href="#">consectetur adipiscing elit</a>. Pellentesque tincidunt lectus velit, vel pulvinar nibh auctor nec.
									{ /* eslint-enable jsx-a11y/anchor-is-valid */}
								</Card.Text>
							</Card.Body>
						</Card>
					</Col>
					<Col md="4">
						<Card bg="tertiary">
							<Card.Body>
								<Card.Title>
									Tertiary
								</Card.Title>
								<Card.Text>
									{ /* eslint-disable jsx-a11y/anchor-is-valid */}
									Lorem ipsum dolor sit amet, <a href="#">consectetur adipiscing elit</a>. Pellentesque tincidunt lectus velit, vel pulvinar nibh auctor nec.
									{ /* eslint-enable jsx-a11y/anchor-is-valid */}
								</Card.Text>
							</Card.Body>
						</Card>
					</Col>
				</Row>
				<Row>
					<Col md="12">
						<h2>Header Elements</h2>
						<hr />
					</Col>
					<Col md="6">
						<ReportHeadingCard />
					</Col>
				</Row>
				<Row>
					<Col md="12">
						<h2>Typography</h2>
						<hr />
					</Col>
					<Col md="6">
						<h1>Open Sans</h1>
						<p>Headers</p>
					</Col>
					<Col md="6">
						<p>The Open Sans typeface is intended to be used as a header and other title type content. Use Bold for large and secondary or lengthy titles.</p>
						<p><strong>Body Copy - Open Sans</strong><br />
							Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros.</p>
					</Col>
					<Col md="12">
						<h1>H1 Heading Styling</h1>
						<h2>H2 Heading Styling</h2>
						<h3>H3 Heading Styling</h3>
						<h4>H4 Heading Styling</h4>
						<p>50 words Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in.</p>
						<Button variant="primary">Primary Button</Button>
						<Button variant="secondary">Secondary Button</Button>
						<Row>
							<Col md="6">
								<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent elit lorem, posuere eget egestas lobortis, rhoncus et dui. Cras vel nisi posuere, aliquam arcu mollis, feugiat lectus. Praesent consequat hendrerit sem vel auctor. Maecenas fringilla purus et urna scelerisque, ac pretium tortor posuere. Nam ac rutrum massa, at accumsan odio. Ut pretium massa sed est condimentum, in sagittis sapien tincidunt. Morbi a ligula id neque ullamcorper bibendum. Sed non lorem non nisl interdum auctor et finibus nunc.</p>
							</Col>
							<Col md="6">
								<p>Donec aliquam fringilla iaculis. Nam sagittis condimentum gravida. Sed faucibus, metus eu mattis commodo, sapien justo convallis tellus, non dictum velit odio sed ex. Aenean lacus sapien, iaculis vel mollis non, luctus nec nibh. Morbi auctor, arcu eu tempus vestibulum, ante justo eleifend nunc, vel pulvinar lacus ante accumsan ligula. Sed eget suscipit est. Cras volutpat fringilla eros, id posuere ex pharetra sit amet. Sed vel libero quis turpis laoreet gravida. Nunc aliquet dictum quam, ut hendrerit libero pulvinar vitae. Suspendisse at lacus porta, eleifend dolor sit amet, porta augue.</p>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row>
					<Col md="12">
						<h2>Notifications</h2>
						<hr />
					</Col>
					<Col md="12">
						<Alert>
							<span className="alert-message">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur faucibus commodo lobortis. Vestibulum eget justo eget augue imperdiet semper pharetra ac lectus.</span>
						</Alert>
						<Alert variant="danger">
							<span className="alert-icon">
								<FontAwesomeIcon icon={faExclamationTriangle} />
							</span>
							<span className="alert-message">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur faucibus commodo lobortis. Vestibulum eget justo eget augue imperdiet semper pharetra ac lectus.</span>
							<span className="alert-icon">
								<FontAwesomeIcon icon={faExclamationTriangle} />
							</span>
						</Alert>
					</Col>
				</Row>
				<Row>
					<Col md="12">
						<h2>Forms</h2>
						<hr />
					</Col>
					<Col md="12">
						<FormExample />
					</Col>
				</Row>
				<Row>
					<Col md="12">
						<h2>Icons</h2>
						<hr />
					</Col>
					<Col md="12">
						<p>Using the <a href="https://fontawesome.com/icons">Font Awesome</a> library via <a href="https://github.com/FortAwesome/react-fontawesome">React Fontawesome</a>.</p>
						<FontAwesomeIcon size="lg" icon={faInfoCircle} />
						<FontAwesomeIcon size="lg" icon={faCheckCircle} />
						<FontAwesomeIcon size="lg" icon={faExclamationCircle} />
						<FontAwesomeIcon size="lg" icon={faExclamationTriangle} />
						<FontAwesomeIcon size="lg" icon={faPencilAlt} />
						<FontAwesomeIcon size="lg" icon={faFilePdf} />
						<FontAwesomeIcon size="lg" icon={faFileWord} />
						<FontAwesomeIcon size="lg" icon={faFileAlt} />
						<FontAwesomeIcon size="lg" icon={faSearch} />
						<FontAwesomeIcon size="lg" icon={faArrowLeft} />
						<FontAwesomeIcon size="lg" icon={faArrowRight} />
						<FontAwesomeIcon size="lg" icon={faArrowUp} />
						<FontAwesomeIcon size="lg" icon={faArrowDown} />
						<FontAwesomeIcon size="lg" icon={faLock} />
						<FontAwesomeIcon size="lg" icon={faClock} />
						<FontAwesomeIcon size="lg" icon={faCalendar} />
						<FontAwesomeIcon size="lg" icon={faBell} />
						<FontAwesomeIcon size="lg" icon={faCloudUploadAlt} />
						<FontAwesomeIcon size="lg" icon={faPrint} />
						<FontAwesomeIcon size="lg" icon={faTimesCircle} />
						<FontAwesomeIcon size="lg" icon={faShare} />
						<FontAwesomeIcon size="lg" icon={faShareAlt} />
						<FontAwesomeIcon size="lg" icon={faPaperclip} />
					</Col>
				</Row>
			</Container>

			<Footer />
		</div>
	);
}

export default App;
