import React from 'react';

export default function RadioButton(props) {
	const { labelText, labelPosition, checked, onChange, defaultChecked, id, value, name } = props;

	let position = 'left';

	if (labelPosition) {
		position = labelPosition;
	}

	const containerStyles = {
		display: 'flex',
		alignItems: 'center',
		flexDirection: position === 'left' ? 'row-reverse' : 'row',
		justifyContent: position === 'left' ? 'end' : 'start'
	};

	const labelStyles = {
		marginRight: position === 'right' ? '0' : '1rem',
		marginLeft: position === 'left' ? '0' : '1rem'
	};

	return (
		<>
			<label className="radio-container" style={containerStyles}>
				<input
					type="radio"
					name={name}
					value={value}
					id={id}
					className="radio-input"
					checked={checked}
					onChange={onChange}
					defaultChecked={defaultChecked}
				/>
				<span className="radio"></span>
				<span style={labelStyles}>{labelText}</span>
			</label>
		</>
	);
}
