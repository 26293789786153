import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import logger from 'redux-logger';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { authenticate as authenticateReducer } from './reducers/authenticateReducer';
import {
	globalMessages as globalMessagesReducer,
	hasChanges as hasChangesReducer
} from './reducers/globalMessagesReducer';
import { language as languageReducer } from './reducers/languageReducer';

const reducers = combineReducers({
	authenticate: authenticateReducer,
	globalMessages: globalMessagesReducer,
	hasChanges: hasChangesReducer,
	language: languageReducer
});

const persistConfig = {
	key: 'root',
	whitelist: [
		'language'
	],
	storage
};

const persistedReducer = persistReducer(persistConfig, (state, action) => reducers(state, action));

export const store = createStore(
	persistedReducer,
	applyMiddleware(
		thunkMiddleware,
		logger
	)
);

export const persistor = persistStore(store);
