/* eslint-disable id-length */
import React, { useEffect } from 'react';
import { renderToString } from 'react-dom/server';
import { ReactTitle } from 'react-meta-tags';
import { useLocation } from 'react-router-dom';
import { Translate, Translator } from 'react-translated';
import { useSelector } from 'react-redux';
import { Markup } from 'interweave';

import { dataLayerPageView } from '../../helpers/miscHelpers';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Header from '../layout/Header';
import Footer from '../layout/Footer';

import Copy from '../../content/home/copy.md';

function Home() {
	const { PAGE_TITLE_BASE } = require('../../config/constants');
	let location = useLocation();
	const currentPath = location.pathname;
	const introCopy = (Copy) ? renderToString(<Copy />) : null;

	// State data.
	const language = useSelector(state => state.language);

	useEffect(() => {
		const pageTitle = PAGE_TITLE_BASE + ' - Home';

		dataLayerPageView(
			currentPath,
			pageTitle,
			language.code
		);
	}, [language]);

	return (
		<>
			<Translator>
				{({ translate }) => (
					<ReactTitle
						title={
							translate({
								text: '{siteTitle} - Home',
								data: { siteTitle: translate({ text: PAGE_TITLE_BASE }) }
							})
						}
					/>
				)}
			</Translator>

			<Header />

			<Container as="main" fluid={true} className="main" id="main" tabIndex="-1">
				<Row>
					<Col md={6}>
						<h1>
							<Translate text="Home" />
						</h1>

						{ introCopy &&
							<Markup content={ introCopy } />
						}
					</Col>
				</Row>
			</Container>

			<Footer />
		</>
	);
}

export default Home;
