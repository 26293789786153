/* eslint-disable id-length */
import React, { useState, useEffect, useRef } from 'react';
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';
import { Provider } from 'react-translated';
import translation from './translations';
import { useSelector, useDispatch } from 'react-redux';
import { useBeforeunload } from 'react-beforeunload';
import IdleTimer from 'react-idle-timer';
import {
	INACTIVITY_TIMEOUT,
	LANGUAGE_FRENCH_CODE,
	LANGUAGE_ENGLISH_CODE,
	LANGUAGE_MAPPINGS,
	LANGUAGE_FRENCH,
	LANGUAGE_ENGLISH
} from './config/constants';

// Styles.
import './scss/style.scss';

// Pages.
import Home from './components/pages/Home';
import Styleguide from './components/pages/Styleguide';
import Swagger from './components/pages/Swagger';
import NoRoute from './components/pages/NoRoute';
import WorkPlan from './components/pages/WorkPlan';

import { authenticateActions } from './store/actions/authenticateActions';
import { update } from './store/actions/languageActions';
import { setHasChanges } from './store/actions/globalMessagesActions';

// Components.
import GlobalAlert from './components/misc/GlobalAlert';

function App() {
	const timeout = INACTIVITY_TIMEOUT;
	const [timedOut, setTimedOut] = useState(false);
	const timerRef = useRef(null);
	const history = useHistory();
	const dispatch = useDispatch();
	let location = useLocation();
	const currentPath = location.pathname;

	// State data
	const hasChanges = useSelector(state => state.hasChanges);
	const language = useSelector(state => state.language);

	const languageMappings = LANGUAGE_MAPPINGS;
	const [languageCode, setLanguageCode] = useState(LANGUAGE_ENGLISH_CODE);

	// Setting language.
	useEffect(() => {
		if (language.value && Array.isArray(languageMappings)) {
			const found = languageMappings.find(item => item.name == language.value);

			if (found) {
				setLanguageCode(found.code);
				document.documentElement.setAttribute('lang', found.code);
			}
		}
	}, [language]);

	// Detecting FR URL
	useEffect(() => {
		if (currentPath == '/fr') {
			dispatch(update(LANGUAGE_FRENCH, LANGUAGE_FRENCH_CODE));
			history.replace('/');
		}

		if (currentPath == '/en') {
			dispatch(update(LANGUAGE_ENGLISH, LANGUAGE_ENGLISH_CODE));
			history.replace('/');
		}
	}, [currentPath]);

	// Get authentication info
	useEffect(() => {
		dispatch(authenticateActions.retrieve());

		// Little polyfill for .closest() in IE11
		if (window.Element && !Element.prototype.closest) {
			Element.prototype.closest =
			function(s) {
				var matches = (this.document || this.ownerDocument).querySelectorAll(s),
					i,
					el = this;
				do {
					i = matches.length;
					while (--i >= 0 && matches.item(i) !== el) {}; // eslint-disable-line
				} while ((i < 0) && (el = el.parentElement));
				return el;
			};
		}
	}, []);

	// Timeout functions
	const handleOnActive = () => {
		setTimedOut(false);
	};

	const handleOnIdle = () => {
		if (timedOut) {
			// Slight delay for any functions on unmount to execute.
			setTimeout(function() {
				dispatch(setHasChanges(false));
				history.replace({
					pathname: '/',
					state: {
						timedOut: true
					}
				});
			}, 500);
		} else {
			timerRef.current.reset();
			setTimedOut(true);
		}
	};

	const handleOnAction = () => {
		setTimedOut(false);
	};

	// Popup when user tries to leave with changes made.
	const handleOnLeave = (event) => {
		if (!hasChanges) {
			return undefined; // eslint-disable-line
		}

		const message = event.returnValue = 'Changes you made may not be saved.';
		(event || window.event).returnValue = message;

		return message;
	};

	useBeforeunload(event => {
		if (hasChanges) {
			return handleOnLeave(event);
		}
	});

	return (
		<>
			<IdleTimer
				ref={ timerRef }
				element={ document }
				onActive={ handleOnActive }
				onIdle={ handleOnIdle }
				onAction={ handleOnAction }
				debounce={ 250 }
				timeout={ timeout }
			/>

			<Provider language={ languageCode } translation={ translation }>
				<div className="page-wrapper">
					<Switch>
						<Route
							exact
							path={[
								'/',
								'/fr',
								'/en'
							]}
							component={ Home }
						/>
						<Route
							exact
							path={[
								'/work-plan'
							]}
							component={ WorkPlan }
						/>
						<Route
							path={[
								'/styleguide'
							]}
							component={ Styleguide }
						/>
						<Route
							path={[
								'/swagger'
							]}
							component={ Swagger }
						/>
						<Route
							path='*'
							component={ NoRoute }
						/>
					</Switch>
					<GlobalAlert />
				</div>
			</Provider>
		</>
	);
}

export default App;
