import axios from 'axios';
const env = window.env;

export const authenticateService = {
	retrieve
};

function retrieve() {
	const requestConfig = {
		method: 'POST',
		url: `${env.BASE_API_URL}/authenticate`
	};

	return axios(requestConfig)
		.then(response => handleResponse(response))
		.catch(error => handleErrorResponse(error));
}

function handleResponse(response) {
	const data = response.data;

	if (data.length === 0) {
		return {};
	}

	return data;
}

function handleErrorResponse(error) {
	console.log(error); // eslint-disable-line
	return {error: 'Error: Network Error'};
}
