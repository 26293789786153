import React from 'react';

export default function TextInput(props) {
	const {
		labelText,
		placeHolder,
		value,
		onChange,
		required,
		id,
		valid,
		invalidMessage,
		multiline
	} = props;

	return (
		<div className={`w-100 mb-2 ${valid ? '' : 'has-error'}`}>
			<label htmlFor={id}>
				{labelText}
				{required && <span className="required">*</span>}
			</label>
			{ !multiline ? <input
				type="text"
				id={id}
				className={`form-control ${valid ? '' : 'is-invalid'}`}
				placeholder={placeHolder}
				value={value}
				onChange={onChange}
			/> : <textarea
				id={id}
				className={`form-control ${valid ? '' : 'is-invalid'}`}
				placeholder={placeHolder}
				value={value}
				onChange={onChange}
				rows={3} />}
			{!valid && <div className="invalid-feedback mt-0">{invalidMessage}</div>}
		</div>
	);
}
