import React from 'react';
import { ReactTitle } from 'react-meta-tags';
import { Translate, Translator } from 'react-translated';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Header from '../layout/Header';
import Footer from '../layout/Footer';

function NoRoute() {
	const { PAGE_TITLE_BASE } = require('../../config/constants');

	return (
		<>
			<Translator>
				{({ translate }) => (
					<ReactTitle
						title={
							translate({
								text: '{siteTitle} - Not Found',
								data: { siteTitle: translate({ text: PAGE_TITLE_BASE }) }
							})
						}
					/>
				)}
			</Translator>

			<Header />

			<Container as="main" fluid={ true } className="main" id="main" tabIndex="-1">
				<Row>
					<Col>
						<h1><Translate text="404 - Not Found" /></h1>
					</Col>
				</Row>
			</Container>

			<Footer />
		</>
	);
}

export default NoRoute;
