/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import CheckBox from '../custom/CheckBox';
import TextInput from '../custom/TextInput';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

export default function StoryTable(props) {

	const {
		ticketsArray,
		currency,
		tableHeaders,
		selectOptions,
		hideRowOptions,
		tableFoot,
		redIssueKey,
		issuesCount,
		sprintInfo,
		budget } =
		props;

	// Define state variables
	const [tickets, setTickets] = useState(ticketsArray);
	const [sortKey, setSortKey] = useState('id');
	const [sortOrder, setSortOrder] = useState('asc');
	const [selected, setSelected] = useState([]);
	const [hiddenTickets, setHiddenTickets] = useState([]);
	const [textValue, setTextValue] = useState('');
	const [valid, setValid] = useState(true);
	const [comment, setComment] = useState(null);
	const [showCommentBox, setShowCommentBox] = useState(false);
	const [draggingTicket, setDraggingTicket] = useState(false);

	const totalCost = tickets.reduce((accumulator, item) => accumulator + item.cost, 0);

	let budgetRemaining = budget - totalCost;

	const handleCheckboxChange = (ticket) => {
		if (selected.includes(ticket)) {
			setSelected(selected.filter((id) => id !== ticket));
		} else {
			setSelected([...selected, ticket]);
		}
	};

	const handleSelectAll = (e) => {
		if (e.target.checked) {
			setSelected(tickets);
		} else {
			setSelected([]);
		}
	};

	const handleHideClick = (e, ticket) => {
		// Implement hide/unhide functionality
		if (!hiddenTickets.includes(ticket.id)) {
			setHiddenTickets((hiddenTickets) => [...hiddenTickets, ticket.id]);
			e.target.parentNode.style.color = 'gray';
		} else {
			setHiddenTickets(hiddenTickets.filter(id => id !== ticket.id));
			e.target.parentNode.style.color = '';
		}
	};

	// Define functions to handle sorting and hiding rows
	function handleSort(key) {
		const newSortOrder = key === sortKey && sortOrder === 'asc' ? 'desc' : 'asc';
		setSortKey(key);
		setSortOrder(newSortOrder);
		setTickets(sortData(tickets, key, newSortOrder === 'desc'));
	}

	function sortData(tableData, sortKey, reverse) {
		// sorting logic
		const sortedData = [...tableData].sort((alpha, beta) => {
			const valueA = alpha[sortKey];
			const valueB = beta[sortKey];

			if (valueA < valueB) {
				return reverse ? 1 : -1;
			}
			if (valueA > valueB) {
				return reverse ? -1 : 1;
			}
			return 0;
		});

		return sortedData;
	}

	const handleDragStart = (results) => {
		setDraggingTicket(results.draggableId);
	};

	const handleDragEnd = (results) => {
		// Implement drag and drop functionality
		setDraggingTicket(false);
		if (!results.destination) { return; }
		let tempTickets = [...tickets];
		let [selectedRow] = tempTickets.splice(results.source.index, 1);
		tempTickets.splice(results.destination.index, 0, selectedRow);
		setTickets(tempTickets);
	};

	const handleSubmit = (e) => {
		e.preventDefault(); // Prevent default form submission behavior
		if (textValue !== '') {
			setValid(true);
			setComment(textValue);
			setShowCommentBox(false);
		} else {
			setValid(false);
		}
	};

	const handleTextChange = (e) => {
		setTextValue(e.target.value);
	};

	const toggleCommentBox = () => {
		if (showCommentBox === false) {
			setShowCommentBox(true);
		} else {
			setShowCommentBox(false);
		}
	};

	useEffect(() => {
		//Runs only on the first render
		setTickets(sortData(tickets, sortKey, sortOrder === 'asc'));
	}, []);

	return (
		<>
			{sprintInfo ? <>
				<div className='mb-3'>
					<span className='mr-5'>SPRINT STARTING: <strong>{'2023-04-28'}</strong></span><span>SPRINT ENDING: <strong>{'2023-04-28'}</strong></span>
				</div>

				{comment !== null || '' ? <div className='mb-3'>{comment}</div> : <Button variant='secondary' onClick={toggleCommentBox}>Add Comment</Button>}

				{showCommentBox ? <>
					<>
						<form onSubmit={handleSubmit} >
							<Row>
								<Col md="6">
									<TextInput
										id='testId'
										labelText='Comment'
										value={textValue}
										onChange={handleTextChange}
										valid={valid}
										invalidMessage='Please enter a valid project or epic key'
										multiline
									/>
									<Button type='submit' variant="primary">Save</Button>
								</Col>
							</Row>
						</form>
					</>
				</> : null}
			</> : null}

			{issuesCount && <h5>ISSUES ({tickets.length})</h5>}
			<DragDropContext onDragStart={(results) => handleDragStart(results)} onDragEnd={(results) => handleDragEnd(results)}>
				<Table bordered hover responsive>
					<thead>
						{selectOptions ? <CheckBox labelPosition={'right'} onChange={(e) => handleSelectAll(e)}></CheckBox> : null}
						<tr>
							{selectOptions ? <th>INCLUDE</th> : null}
							{tableHeaders.map((row) => {
								return (
									<th className={row.key === 'cost' ? 'text-end' : null} key={row.key} onClick={() => handleSort(row.key)}>
										{row.label} {row.key === 'cost' ? '$' + currency : null}
									</th>
								);
							})}
							{hideRowOptions ? <th className='invisibleTd'></th> : null}
						</tr>
					</thead>
					<Droppable droppableId="tbody">
						{(provided) => (
							<tbody ref={provided.innerRef} {...provided.droppableProps}>
								{tickets.map((ticket, index) => (
									<Draggable key={ticket.id} draggableId={`ticket-${ticket.id}`} index={index}>
										{(provided) => (
											<tr ref={provided.innerRef}
												className={draggingTicket === `ticket-${ticket.id}` ? 'dragging' : ''}
												{...provided.draggableProps}
												{...provided.dragHandleProps} >
												{selectOptions ? <td>
													<CheckBox labelPosition={'right'} checked={selected.includes(ticket)} onChange={() => handleCheckboxChange(ticket)}></CheckBox>
												</td> : null}
												{tableHeaders.map((header) => {
													return (
														<td className={(header.key === 'cost' ? 'text-end' : null) + ' ' + (redIssueKey && header.key === 'issue_key' ? 'text-red' : null)}>{header.key === 'cost' ? '$' : null}{ticket[header.key]}</td>
													);
												})}
												{hideRowOptions ? <td className='invisibleTd' onClick={(e) => handleHideClick(e, ticket)}>{hiddenTickets.includes(ticket.id) ? 'Unhide' : 'Hide'}</td> : null}
											</tr>
										)}
									</Draggable>
								))}

								{provided.placeholder}
							</tbody>
						)}
					</Droppable>
					{tableFoot ? <tfoot>
						<tr>
							{selectOptions ? <td></td> : null}
							<td></td>
							<td></td>
							<td>TOTAL {currency}:</td>
							<td className='text-end'>${totalCost}</td>
							{hideRowOptions ? <td className='invisibleTd'></td> : null}
						</tr>
						<tr>
							{selectOptions ? <td></td> : null}
							<td></td>
							<td></td>
							<td>TOTAL BUDGET: </td>
							<td className='text-end'>${budget}</td>
							{hideRowOptions ? <td className='invisibleTd'></td> : null}
						</tr>
						<tr>
							{selectOptions ? <td></td> : null}
							<td></td>
							<td></td>
							<td>REMAINING BUDGET:</td>
							<td className='text-end'>${budgetRemaining}</td>
							{hideRowOptions ? <td className='invisibleTd'></td> : null}
						</tr>
					</tfoot> : <tfoot>
						<tr>
							{tableHeaders.map(() => (
								<td></td>
							))}
							{!hideRowOptions ? <td></td> : null}
						</tr>
					</tfoot>}
				</Table>
			</DragDropContext>

		</>
	);
}
