export default [
	{
		text: 'Home',
		href: '/',
		activePaths: [
			'/',
			'/en',
			'/fr'
		]
	},
	{
		text: 'Styleguide',
		href: '/styleguide',
		activePaths: [
			'/styleguide'
		]
	},
	{
		text: 'Work Plan',
		href: '/work-plan',
		activePaths: [
			'/work-plan'
		]
	}
];
