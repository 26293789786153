import React from 'react';

export default function ReportHeaderArea({projectName, epicName}) {
	const today = new Date();
	const year = today.getFullYear();
	const month = String(today.getMonth() + 1).padStart(2, '0');
	const day = String(today.getDate()).padStart(2, '0');
	const currentDate = `${year}-${month}-${day}`;

	return (
		<div className="report-heading-card-body">
			<h2>{projectName.toUpperCase()}</h2>
			<p className="bold">{epicName.toUpperCase()}</p>
			<p>REPORT GENERATED: {currentDate}</p>
		</div>
	);
}
