import React from 'react';
import { Modal, Button } from 'react-bootstrap';

export default function CustomModal(props) {
	const { show, onClose, title, body } = props;

	return (
		<Modal show={show} onHide={onClose} centered>
			<Modal.Header closeButton>
				<Modal.Title><h1>{title}</h1></Modal.Title>
			</Modal.Header>
			<Modal.Body>{body}</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={onClose}>
					Close
				</Button>
				<Button variant="primary">Save</Button>
			</Modal.Footer>
		</Modal>
	);
}
