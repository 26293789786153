import React from 'react';

export default function CheckBox(props) {
	const { labelText, labelPosition, checked, onChange, id, value } = props;

	let position = 'left';

	if (labelPosition) {
		position = labelPosition;
	}

	const containerStyles = {
		display: 'flex',
		alignItems: 'center',
		flexDirection: position === 'left' ? 'row-reverse' : 'row',
		justifyContent: position === 'left' ? 'end' : 'start'
	};

	const labelStyles = {
		marginRight: position === 'right' ? '0' : '1rem',
		marginLeft: position === 'left' ? '0' : '1rem'
	};

	return (
		<>
			<label className="check-container" style={containerStyles}>
				<input
					type="checkbox"
					name={id}
					value={value}
					id={id}
					className="check-input"
					checked={checked}
					onChange={onChange}
				/>
				<span className="checkmark"></span>
				<span style={labelStyles}>{labelText}</span>
			</label>
		</>
	);
}
