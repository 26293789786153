import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import CheckBox from '../custom/CheckBox';
import RadioButton from '../custom/RadioButton';
import TextInput from '../custom/TextInput';

export default function InputFields() {
	const [textValue, setTextValue] = useState('');
	const [currency, setSetCurrency] = useState('CAD');
	const [options, setOptions] = useState([]);
	const [valid, setValid] = useState(true);

	const handleTextChange = (e) => {
		setTextValue(e.target.value);
	};

	const handleCurrencyChange = (e) => {
		setSetCurrency(e.target.value);
	};

	const handleOptionsChange = (e) => {
		if (e.target.checked && !options.includes(e.target.value)) {
			setOptions([...options, e.target.value]);
		} else {
			setOptions(options.filter((item) => item !== e.target.value));
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault(); // Prevent default form submission behavior

		if (textValue !== '') {
			// Perform form submission logic here
			setValid(true);
			// eslint-disable-next-line no-console
			console.log('Submitted data:', { textValue, currency, options });
		} else {
			setValid(false);
		}
	};

	return (
		<form onSubmit={handleSubmit} >
			<Row>
				<Col md="6">
					<TextInput
						id='testId'
						labelText='PROJECT KEY/EPIC KEY:'
						value={textValue}
						onChange={handleTextChange}
						valid={valid}
						invalidMessage='Please enter a valid project or epic key'
						required
					/>
					<Button type='submit' variant="primary">Search</Button>
				</Col>
				<Col md="3" className="mb-3">
					<RadioButton labelText='$CAD' id='cad' name='currency' value='CAD' onChange={handleCurrencyChange} defaultChecked></RadioButton>
					<RadioButton labelText='$USD' id='usd' name='currency' value='USD' onChange={handleCurrencyChange}></RadioButton>
				</Col>
				<Col md="3">
					<CheckBox labelText={'STORY POINTS'} id='story_points' value='story_points' onChange={handleOptionsChange}></CheckBox>
					<CheckBox labelText={'SHOW OLDER SPRINTS'} id='show_older_sprints' value='show_older_sprints' onChange={handleOptionsChange}></CheckBox>
					<CheckBox labelText={'FILTER NIL PRICE'} id='filter_nil_price' value='filter_nil_price' onChange={handleOptionsChange}></CheckBox>
					<CheckBox labelText={'BUDGET'} id='budget' value='budget' onChange={handleOptionsChange}></CheckBox>
				</Col>
			</Row>
			<hr/>
		</form>
	);
}
