import React, { useState } from 'react';
import { Translate } from 'react-translated';
import { useLocation } from 'react-router-dom';

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import SkipToMain from '../misc/SkipToMain';

// Icons.
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faBars,
	faTimes
} from '@fortawesome/free-solid-svg-icons';

import navItems from '../../config/navItems';

function Header() {
	const { PAGE_TITLE_BASE } = require('../../config/constants');
	let location = useLocation();
	const currentPath = location.pathname;
	const [menuOpen, setMenuOpen] = useState(false);

	const handleMenuToggle = (expanded) => {
		setMenuOpen(expanded);
	};

	return (
		<>
			<SkipToMain />
			<Navbar
				collapseOnSelect
				expand="lg"
				className="container-fluid align-items-end header"
				onToggle={ handleMenuToggle }
			>
				<Navbar.Brand href="/">
					<Translate text={PAGE_TITLE_BASE} />
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="responsive-navbar-nav">
					<FontAwesomeIcon size="lg" icon={ (menuOpen) ? faTimes : faBars } />
				</Navbar.Toggle>
				<Navbar.Collapse>
					<div className="navbar-wrapper">
						<Nav className="primary-nav justify-content-end">
							{ Array.isArray(navItems) && navItems.map((item, index) => {
								let isActive = false;
								let display = true;

								if (item.activePaths && item.activePaths.length) {
									if (item.activePaths.includes(currentPath)) {
										isActive = true;
									}

									item.activePaths.reduce((a, b) => { // eslint-disable-line
										if (RegExp(b, 'g').test(currentPath)) {
											isActive = true;
										}
									});
								}

								if (display) {
									return (
										<Nav.Item key={ index }>
											<Nav.Link
												href={ item.href }
												active={ isActive }
											>
												<Translate text={ item.text } />
											</Nav.Link>
										</Nav.Item>
									);
								}

								return null;
							})}
						</Nav>
					</div>
				</Navbar.Collapse>
			</Navbar>
		</>
	);
}

export default Header;
