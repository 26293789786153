import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Header from '../layout/Header';
import Footer from '../layout/Footer';

import { useState } from 'react';
import { Button } from 'react-bootstrap';

// Custom components
import InputFields from '../custom/InputFields';
import ReportHeaderArea from '../custom/ReportHeaderArea';
import StoryTable from '../custom/StoryTable';
import CustomModal from '../custom/CustomModal';

function WorkPlan() {
	// Define state variables
	const [tickets] = useState([
		{ id: 1, title: 'Ticket 1', issue_key: 'KOPS-1235', summary: 'aaaaaaaaaaaaaa', resolution_date: 'open', story_points: 23, status: 'Open', cost: 3455 },
		{ id: 2, title: 'Ticket 2', issue_key: 'KOPS-2593', summary: 'bbbbbbbbbbbb', resolution_date: 'open', story_points: 23, status: 'In Progress', cost: 1444 },
		{ id: 3, title: 'Ticket 3', issue_key: 'KOPS-3845', summary: 'ccccccccccccc', resolution_date: 'open', story_points: 23, status: 'Done', cost: 2555 }
	]);
	const [projectSummaryHeaders] = useState([
		{ key: 'issue_key', label: 'ISSUE KEY' },
		{ key: 'summary', label: 'SUMMARY' },
		{ key: 'resolution_date', label: 'RESOLUTION DATE' },
		{ key: 'cost', label: null }
	]);
	
	const [cloningHeaders] = useState([
		{ key: 'issue_key', label: 'ISSUE KEY' },
		{ key: 'story_points', label: 'SPs' },
		{ key: 'summary', label: 'SUMMARY' }
	]);
	
	const [sprintHeaders] = useState([
		{ key: 'issue_key', label: 'ISSUE KEY' },
		{ key: 'summary', label: 'SUMMARY' },
		{ key: 'cost', label: null }
	]);

	const [currency] = useState('CAD');

	const projectName = 'my project';
	const epicName = 'my epic';

	const [showModal, setShowModal] = useState(false);

	const handleClose = () => setShowModal(false);
	const handleShow = () => setShowModal(true);

	return (
		<>
			<Header />

			<Container as="main" fluid={true} className="main" id="main" tabIndex="-1">
				<Row>
					<Col>
						<h1>Workplan</h1>
						<InputFields />
					</Col>
				</Row>
				<Row>
					<Col>
						<ReportHeaderArea projectName={projectName} epicName={epicName} />
					</Col>
				</Row>
				<Row>
					<Col>
						<StoryTable ticketsArray={tickets} currency={currency} tableHeaders={sprintHeaders} hideRowOptions sprintInfo/>
					</Col>
				</Row>
				<Row>
					<Col>
						<StoryTable ticketsArray={tickets} currency={currency} tableHeaders={sprintHeaders} hideRowOptions sprintInfo/>
					</Col>
				</Row>
				<Row>
					<Col>
						<StoryTable ticketsArray={tickets} currency={currency} tableHeaders={sprintHeaders} hideRowOptions sprintInfo/>
					</Col>
				</Row>
				<Row>
					<Col>
						<hr />
						<StoryTable ticketsArray={tickets} currency={currency} tableHeaders={projectSummaryHeaders} hideRowOptions tableFoot redIssueKey issuesCount budget={10000} />
					</Col>
				</Row>
				<Row>
					<Col>
						<StoryTable ticketsArray={tickets} currency={currency} tableHeaders={cloningHeaders} selectOptions />
					</Col>
				</Row>
				<Row>
					<Col>
						<Button variant="primary" onClick={handleShow}>
							Launch demo modal
						</Button>
					</Col>
				</Row>
			</Container>

			<CustomModal show={showModal} onClose={handleClose} title='Demo Modal' body={'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi. Nulla quis sem at nibh elementum imperdiet. Duis sagittis ipsum. Praesent mauris'}></CustomModal>

			<Footer />
		</>
	);
}

export default WorkPlan;
